import { AxiosInstance } from 'axios'
import { IJsonApiSerializer, IJsonResource, IJsonResponse } from '../JsonApiSerializer'

class ReservationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async updateCheckIn({ id, checkIn }) {
    const param = {
      reservation: { check_in: checkIn },
    }

    const { data } = await this.httpClient.put(`/api/reservations/${id}/update_check_in`, param)

    const updatedReservation = this.serializer.parseResourceData(
      data.reservation,
      data.reservation.data
    )

    return { updatedReservation, flush: data.flush }
  }

  public parseRelationships(resources: IJsonResponse, resource: IJsonResource | IJsonResource[]) {
    return this.serializer.parseResourceData(resources, resource)
  }

  public getReservationFromJson(data) {
    const reservation = this.serializer.parseResourceData(data, data.data)

    return { reservation }
  }

  public getReservationsFromJson(data) {
    const reservations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { reservations, pagination }
  }

  public getTourReservationsFromJson(data) {
    const tour_reservations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { tour_reservations, pagination }
  }

  // 乳幼児だけのケースは起こり得ないと思うので大人と子供のみカウント
  public getGuestCount(guests: { num_of_adults: number; num_of_children?: number }) {
    const adults = guests.num_of_adults
    const children = guests.num_of_children ?? 0
    return adults + children
  }

  public async searchReservations(params) {
    const { data } = await this.httpClient.get(`/api/reservations/search`, { params })
    const reservations = this.serializer.parseResourceData(
      data.reservations,
      data.reservations.data
    )
    const pagination = data.reservations.meta

    return { reservations, pagination }
  }

  public async createSecret(params) {
    const { data } = await this.httpClient.post('/api/reservations/prepare', { ...params })

    return { secret: data }
  }

  public async createReservation(params) {
    const { data } = await this.httpClient.post(`/api/reservations`, { ...params })
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async createReservationByNonMember(params) {
    const { data } = await this.httpClient.post(`/api/reservations/create_by_non_member`, {
      ...params,
    })
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async approveReservation(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/approve`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async declineReservation(id, params?) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/decline`, params)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async cancelReservation(id, params) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/cancel`, params)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async refundAmountReservation(id) {
    const { data } = await this.httpClient.get(`/api/reservations/${id}/refund_amount`)
    const { price } = data

    return { price }
  }

  public async createMessage(formData) {
    const { data } = await this.httpClient.post(`/api/messages`, formData)
    const message = this.serializer.parseResourceData(data.message, data.message.data)

    return { message }
  }

  public async createReceipt(config) {
    const { data } = await this.httpClient.post('/api/receipts', { receipt: { ...config } })
    return data
  }

  public async getReceipt(id) {
    const { data } = await this.httpClient.get(`/api/receipts/${id}`)
    return data
  }

  // ガイドアサイン-通常予約時にエージェントに紐づくガイドを取得する
  public async getReservationGuides(id, params) {
    const { data } = await this.httpClient.get(
      `/api/reservations/${id}/guide_assigns/reservation_guides`,
      {
        params,
      }
    )
    const guides = this.serializer.parseResourceData(data.guides.users, data.guides.users.data)
    const guide_calendars = data.guide_calendars
      ? this.serializer.parseResourceData(data.guide_calendars, data.guide_calendars.data)
      : []

    const pagination = data.guides.users.meta
    return { guides, pagination, guide_calendars }
  }

  // ガイドアサイン 通常予約からエージェントとガイドの会話を開始
  public async createGuideConversation(params) {
    const {
      data,
    } = await this.httpClient.post(
      `/api/reservations/${params.id}/guide_assigns/reservation_create_guide_conversation`,
      { ...params.params }
    )
    return { conversationId: data.conversation_id }
  }

  // 通常予約からガイドアサイン
  public async reservationGuideAssign(params) {
    const {
      data,
    } = await this.httpClient.put(
      `/api/reservations/${params.id}/guide_assigns/reservation_assign`,
      { ...params.params }
    )
    const { flush } = data.reservation
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { flush }
  }
  // 通常予約からガイドアサイン解除
  public async reservationGuideUnassign(params) {
    const {
      data,
    } = await this.httpClient.put(
      `/api/reservations/${params.id}/guide_assigns/reservation_unassign`,
      { ...params.params }
    )
    const { flush } = data.reservation
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { flush }
  }

  // 会話情報を取得する
  public async getReservationMessages(params: { conversation_id: any }) {
    const { data } = await this.httpClient.get('/api/messages', { params })
    return { data }
  }

  // auto_thanks更新
  public async toggleAutoThanks(id) {
    const { data } = await this.httpClient.put(`/api/tour_reservations/${id}/toggle_auto_thanks`)
    const tour_reservation = this.serializer.parseResourceData(
      data.tour_reservation,
      data.tour_reservation.data
    )

    return { tour_reservation }
  }

  public async getReservationImportData() {
    const { data } = await this.httpClient.get('/api/reservation_imports/new')
    return data
  }

  public async executeReservationImport() {
    const { data } = await this.httpClient.post('/api/reservation_imports')
    return data
  }

  public async createCancelApplication(params) {
    const { data } = await this.httpClient.post(`/api/cancel_applications`, { ...params })
    const { flush } = data
    const cancel_application = this.serializer.parseResourceData(
      data.cancel_application,
      data.cancel_application.data
    )

    return { cancel_application, flush }
  }

  public async approveCancelApplication(id) {
    const { data } = await this.httpClient.put(`/api/cancel_applications/${id}/approve`)
    const { flush } = data
    const cancel_application = this.serializer.parseResourceData(
      data.cancel_application,
      data.cancel_application.data
    )

    return { cancel_application, flush }
  }

  public async declineCancelApplication(id) {
    const { data } = await this.httpClient.put(`/api/cancel_applications/${id}/decline`)
    const { flush } = data
    const cancel_application = this.serializer.parseResourceData(
      data.cancel_application,
      data.cancel_application.data
    )

    return { cancel_application, flush }
  }
}

export default ReservationService
